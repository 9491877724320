function camelize(value) {
  return value.replace(/(?:[_-])([a-z0-9])/g, ((_, char) => char.toUpperCase()));
}

function capitalize(value) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

function dasherize(value) {
  return value.replace(/([A-Z])/g, ((_, char) => `-${char.toLowerCase()}`));
}

function readInheritableStaticArrayValues(constructor, propertyName) {
  const ancestors = getAncestorsForConstructor(constructor);
  return Array.from(ancestors.reduce(((values, constructor) => {
    getOwnStaticArrayValues(constructor, propertyName).forEach((name => values.add(name)));
    return values;
  }), new Set));
}

function readInheritableStaticObjectPairs(constructor, propertyName) {
  const ancestors = getAncestorsForConstructor(constructor);
  return ancestors.reduce(((pairs, constructor) => {
    pairs.push(...getOwnStaticObjectPairs(constructor, propertyName));
    return pairs;
  }), []);
}

function getAncestorsForConstructor(constructor) {
  const ancestors = [];
  while (constructor) {
    ancestors.push(constructor);
    constructor = Object.getPrototypeOf(constructor);
  }
  return ancestors.reverse();
}

function getOwnStaticArrayValues(constructor, propertyName) {
  const definition = constructor[propertyName];
  return Array.isArray(definition) ? definition : [];
}

function getOwnStaticObjectPairs(constructor, propertyName) {
  const definition = constructor[propertyName];
  return definition ? Object.keys(definition).map((key => [ key, definition[key] ])) : [];
}

(() => {
  function extendWithReflect(constructor) {
    function extended() {
      return Reflect.construct(constructor, arguments, new.target);
    }
    extended.prototype = Object.create(constructor.prototype, {
      constructor: {
        value: extended
      }
    });
    Reflect.setPrototypeOf(extended, constructor);
    return extended;
  }
  function testReflectExtension() {
    const a = function() {
      this.a.call(this);
    };
    const b = extendWithReflect(a);
    b.prototype.a = function() {};
    return new b;
  }
  try {
    testReflectExtension();
    return extendWithReflect;
  } catch (error) {
    return constructor => class extended extends constructor {};
  }
})();

function ClassPropertiesBlessing(constructor) {
  const classes = readInheritableStaticArrayValues(constructor, "classes");
  return classes.reduce(((properties, classDefinition) => Object.assign(properties, propertiesForClassDefinition(classDefinition))), {});
}

function propertiesForClassDefinition(key) {
  return {
    [`${key}Class`]: {
      get() {
        const {classes: classes} = this;
        if (classes.has(key)) {
          return classes.get(key);
        } else {
          const attribute = classes.getAttributeName(key);
          throw new Error(`Missing attribute "${attribute}"`);
        }
      }
    },
    [`${key}Classes`]: {
      get() {
        return this.classes.getAll(key);
      }
    },
    [`has${capitalize(key)}Class`]: {
      get() {
        return this.classes.has(key);
      }
    }
  };
}

function TargetPropertiesBlessing(constructor) {
  const targets = readInheritableStaticArrayValues(constructor, "targets");
  return targets.reduce(((properties, targetDefinition) => Object.assign(properties, propertiesForTargetDefinition(targetDefinition))), {});
}

function propertiesForTargetDefinition(name) {
  return {
    [`${name}Target`]: {
      get() {
        const target = this.targets.find(name);
        if (target) {
          return target;
        } else {
          throw new Error(`Missing target element "${name}" for "${this.identifier}" controller`);
        }
      }
    },
    [`${name}Targets`]: {
      get() {
        return this.targets.findAll(name);
      }
    },
    [`has${capitalize(name)}Target`]: {
      get() {
        return this.targets.has(name);
      }
    }
  };
}

function ValuePropertiesBlessing(constructor) {
  const valueDefinitionPairs = readInheritableStaticObjectPairs(constructor, "values");
  const propertyDescriptorMap = {
    valueDescriptorMap: {
      get() {
        return valueDefinitionPairs.reduce(((result, valueDefinitionPair) => {
          const valueDescriptor = parseValueDefinitionPair(valueDefinitionPair);
          const attributeName = this.data.getAttributeNameForKey(valueDescriptor.key);
          return Object.assign(result, {
            [attributeName]: valueDescriptor
          });
        }), {});
      }
    }
  };
  return valueDefinitionPairs.reduce(((properties, valueDefinitionPair) => Object.assign(properties, propertiesForValueDefinitionPair(valueDefinitionPair))), propertyDescriptorMap);
}

function propertiesForValueDefinitionPair(valueDefinitionPair) {
  const definition = parseValueDefinitionPair(valueDefinitionPair);
  const {key: key, name: name, reader: read, writer: write} = definition;
  return {
    [name]: {
      get() {
        const value = this.data.get(key);
        if (value !== null) {
          return read(value);
        } else {
          return definition.defaultValue;
        }
      },
      set(value) {
        if (value === undefined) {
          this.data.delete(key);
        } else {
          this.data.set(key, write(value));
        }
      }
    },
    [`has${capitalize(name)}`]: {
      get() {
        return this.data.has(key) || definition.hasCustomDefaultValue;
      }
    }
  };
}

function parseValueDefinitionPair([token, typeDefinition]) {
  return valueDescriptorForTokenAndTypeDefinition(token, typeDefinition);
}

function parseValueTypeConstant(constant) {
  switch (constant) {
   case Array:
    return "array";

   case Boolean:
    return "boolean";

   case Number:
    return "number";

   case Object:
    return "object";

   case String:
    return "string";
  }
}

function parseValueTypeDefault(defaultValue) {
  switch (typeof defaultValue) {
   case "boolean":
    return "boolean";

   case "number":
    return "number";

   case "string":
    return "string";
  }
  if (Array.isArray(defaultValue)) return "array";
  if (Object.prototype.toString.call(defaultValue) === "[object Object]") return "object";
}

function parseValueTypeObject(typeObject) {
  const typeFromObject = parseValueTypeConstant(typeObject.type);
  if (typeFromObject) {
    const defaultValueType = parseValueTypeDefault(typeObject.default);
    if (typeFromObject !== defaultValueType) {
      throw new Error(`Type "${typeFromObject}" must match the type of the default value. Given default value: "${typeObject.default}" as "${defaultValueType}"`);
    }
    return typeFromObject;
  }
}

function parseValueTypeDefinition(typeDefinition) {
  const typeFromObject = parseValueTypeObject(typeDefinition);
  const typeFromDefaultValue = parseValueTypeDefault(typeDefinition);
  const typeFromConstant = parseValueTypeConstant(typeDefinition);
  const type = typeFromObject || typeFromDefaultValue || typeFromConstant;
  if (type) return type;
  throw new Error(`Unknown value type "${typeDefinition}"`);
}

function defaultValueForDefinition(typeDefinition) {
  const constant = parseValueTypeConstant(typeDefinition);
  if (constant) return defaultValuesByType[constant];
  const defaultValue = typeDefinition.default;
  if (defaultValue !== undefined) return defaultValue;
  return typeDefinition;
}

function valueDescriptorForTokenAndTypeDefinition(token, typeDefinition) {
  const key = `${dasherize(token)}-value`;
  const type = parseValueTypeDefinition(typeDefinition);
  return {
    type: type,
    key: key,
    name: camelize(key),
    get defaultValue() {
      return defaultValueForDefinition(typeDefinition);
    },
    get hasCustomDefaultValue() {
      return parseValueTypeDefault(typeDefinition) !== undefined;
    },
    reader: readers[type],
    writer: writers[type] || writers.default
  };
}

const defaultValuesByType = {
  get array() {
    return [];
  },
  boolean: false,
  number: 0,
  get object() {
    return {};
  },
  string: ""
};

const readers = {
  array(value) {
    const array = JSON.parse(value);
    if (!Array.isArray(array)) {
      throw new TypeError("Expected array");
    }
    return array;
  },
  boolean(value) {
    return !(value == "0" || value == "false");
  },
  number(value) {
    return Number(value);
  },
  object(value) {
    const object = JSON.parse(value);
    if (object === null || typeof object != "object" || Array.isArray(object)) {
      throw new TypeError("Expected object");
    }
    return object;
  },
  string(value) {
    return value;
  }
};

const writers = {
  default: writeString,
  array: writeJSON,
  object: writeJSON
};

function writeJSON(value) {
  return JSON.stringify(value);
}

function writeString(value) {
  return `${value}`;
}

class Controller {
  constructor(context) {
    this.context = context;
  }
  static get shouldLoad() {
    return true;
  }
  get application() {
    return this.context.application;
  }
  get scope() {
    return this.context.scope;
  }
  get element() {
    return this.scope.element;
  }
  get identifier() {
    return this.scope.identifier;
  }
  get targets() {
    return this.scope.targets;
  }
  get classes() {
    return this.scope.classes;
  }
  get data() {
    return this.scope.data;
  }
  initialize() {}
  connect() {}
  disconnect() {}
  dispatch(eventName, {target: target = this.element, detail: detail = {}, prefix: prefix = this.identifier, bubbles: bubbles = true, cancelable: cancelable = true} = {}) {
    const type = prefix ? `${prefix}:${eventName}` : eventName;
    const event = new CustomEvent(type, {
      detail: detail,
      bubbles: bubbles,
      cancelable: cancelable
    });
    target.dispatchEvent(event);
    return event;
  }
}

Controller.blessings = [ ClassPropertiesBlessing, TargetPropertiesBlessing, ValuePropertiesBlessing ];

Controller.targets = [];

Controller.values = {};

class DataBindingController extends Controller {
  connect() {
    if (this.element.dataset.bindingDebug === "true") {
      this.debugMode = true;
    }
    this._debug("stimulus-data-binding: connecting to wrapper:", this.element);
    const sourceElements = Array.from(this.element.querySelectorAll("[data-binding-target]"));
    if (this.element.dataset.bindingTarget) sourceElements.unshift(this.element);
    if (sourceElements.length === 0) this._debug("No source elements found. Did you set data-binding-target on your source elements?");
    for (const sourceElement of sourceElements) {
      if (this.debugMode) console.group("stimulus-data-binding: Source element");
      this._debug("Source element found", sourceElement);
      if (sourceElement.dataset.bindingInitial !== "false") {
        this._debug("Running initial binding on source element");
        this._runBindings(sourceElement);
      } else {
        this._debug("%cNot running initial binding on source element as binding-initial is set to false", "color: rgba(150,150,150,0.8);");
      }
      if (this.debugMode) console.groupEnd();
    }
  }
  update(e) {
    this._runBindings(e.currentTarget);
  }
  _runBindings(source) {
    this._debug("Searching for targets for source: ", source);
    for (const targetRef of source.dataset.bindingTarget.split(" ")) {
      const targetElements = this._bindingElements(targetRef);
      if (targetElements.length === 0) this._debug(`Could not find any target elements for ref ${targetRef}. Have you set data-target-ref="${targetRef}" on your target elements?`);
      for (const target of targetElements) {
        if (this.debugMode) console.group("stimulus-data-binding: Target Element");
        this._debug("Target found. Running bindings for target: ", target);
        const bindingCondition = this._getDatum("bindingCondition", source, target);
        if (bindingCondition) {
          this._debug(`Evaluating binding condition: '${bindingCondition}'`);
        } else {
          this._debug(`%cNo binding condition set. Evaluating as true. To add a condition set 'data-binding-condition="..."'`, "color: rgba(150,150,150,0.8);");
        }
        const conditionPassed = this._evaluate(bindingCondition, {
          source: source,
          target: target
        });
        if (conditionPassed) {
          this._debug(`Condition evaluated to: `, conditionPassed);
        } else {
          this._debug(`Condition evaluated to: `, conditionPassed);
        }
        const bindingValue = this._getDatum("bindingValue", source, target);
        if (bindingValue) {
          this._debug(`Evaluating binding value: '${bindingValue}'`);
        } else {
          this._debug(`%cNo binding value set, evaluating as true. to set a value for the attribute / property on your target elements set 'data-binding-value="..."'`, "color: rgba(150,150,150,0.8);");
        }
        const value = this._evaluate(bindingValue, {
          source: source,
          target: target
        });
        this._debug(`Value evaluated to: '${value}'`);
        const bindingAttribute = this._getDatum("bindingAttribute", source, target);
        if (!bindingAttribute) {
          this._debug(`%cNo binding attribute set. To add attributes to your target element set 'data-binding-attribute="..."'`, "color: rgba(150,150,150,0.8);");
        }
        if (bindingAttribute) {
          for (const attribute of bindingAttribute.split(" ")) {
            if (conditionPassed) {
              this._debug(`Condition passed so setting attribute '${attribute}' to '${value}'`);
              target.setAttribute(attribute, value);
            } else {
              this._debug(`Condition failed so removing attribute '${attribute}'`);
              target.removeAttribute(attribute);
            }
          }
        }
        const bindingProperty = this._getDatum("bindingProperty", source, target);
        if (!bindingProperty) {
          this._debug(`%cNo binding property set. To add properties to your target element set 'data-binding-property="..."'`, "color: rgba(150,150,150,0.8);");
        }
        if (bindingProperty) {
          for (const prop of bindingProperty.split(" ")) {
            const propertyValue = conditionPassed ? value : "";
            if (target[prop] != propertyValue) {
              target.dataset.hasChanged = true;
            }
            if (conditionPassed) {
              this._debug(`Condition passed so setting property '${prop}' from ${target[prop]} to '${value}'`);
            } else {
              this._debug(`Condition failed so setting property '${prop}' from ${target[prop]} to '' (empty string)`);
            }
            target[prop] = propertyValue;
          }
        }
        const bindingClass = this._getDatum("bindingClass", source, target);
        if (!bindingClass) {
          this._debug(`%cNo binding class set. To add classes to your target element set 'data-binding-class="..."'`, "color: rgba(150,150,150,0.8);");
        }
        if (bindingClass) {
          for (const klass of bindingClass.split(" ")) {
            if (conditionPassed) {
              this._debug(`Condition passed so adding class '${klass}'`);
              target.classList.add(klass);
            } else {
              this._debug(`Condition failed so removing class '${klass}'`);
              target.classList.remove(klass);
            }
          }
        }
        const bindingEvent = this._getDatum("bindingEvent", source, target);
        if (!bindingEvent) {
          this._debug(`%cNo binding event set. To dispatch events on property change to your target element set 'data-binding-event="..."'`, "color: rgba(150,150,150,0.8);");
        }
        if (bindingEvent) {
          for (const event of bindingEvent.split(" ")) {
            if (target.dataset.hasChanged) {
              this._debug(`Target has changed so dispatching event ${event}`);
              target.dispatchEvent(new Event(event, {
                cancelable: true,
                bubbles: true
              }));
              delete target.dataset.hasChanged;
            } else {
              this._debug(`No changes to target properties so not dispatching '${event}'`);
            }
          }
        }
        if (this.debugMode) console.groupEnd();
      }
    }
  }
  _bindingElements(name) {
    return this.element.querySelectorAll(`[data-binding-ref="${name}"]`);
  }
  _getDatum(attribute, source, target) {
    return target.dataset[attribute] || source.dataset[attribute];
  }
  _evaluate(expression, variables = {}) {
    if (!expression) return true;
    return new Function(Object.keys(variables).map((v => `$${v}`)), `return ${expression.trim()}`)(...Object.values(variables));
  }
  _debug(...args) {
    if (this.debugMode) {
      console.log(...args);
    }
  }
}

export { DataBindingController as default };
